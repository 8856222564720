/* styles.css */
.react-tel-input .form-control {
    width:100% !important;
    border: 1px solid #e0dfdf !important;
    height: auto !important;
}

#chatButton {
    background-color: #30d14f;
    z-index: 1000;
}