.tryAtHome-container {
	padding: .5rem .5rem;
	background: #722257da;
	color: #fff;
	position: fixed;
	bottom: 750px;
	left:1625px;
	font-size: 1rem;
	opacity: 1;
	visibility: visible;
	z-index: 99999;
	backdrop-filter: blur(2px);
	width: 15%;
}

.tryAtHome-container.hide {
	opacity: 0;
	visibility: hidden;
}

.tryAtHome-container a {
	color: #fff !important;
}

.tryAtHome-container a:hover {
	color: #fff !important;
}

.tryAtHome-container .cookie-text {
	color: #fff !important;
	font-family: gillSansMedium !important;
	text-align: center;
	margin-bottom: 5px;

}

.tryAtHome-container .agree {
	text-align: center;
}

.agree button {
	background: #fff;
	color: #722257;
	border: none;
	padding: 0.4rem 1.2rem;
	cursor: pointer;
	border-radius: 20px;
	font-size: .8rem;
}

.agree button:hover {
	background: transparent;
	color: #fff ;
	border: 1px solid #fff;
	font-weight: bold;
}